import * as React from 'react';
import { graphql } from 'gatsby';

// global components
import { renderSwitch } from '../utils/Render';

// styles

export const query = graphql`
    {
        page: craftStyleguideStyleguideEntry {
            id: remoteId
            title
            typeHandle
            contentMatrix {
                ... on Craft_contentMatrix_richTextBlock_BlockType {
                    initialText
                    richText
                    typeHandle
                    actsAsHeader
                    linkToEntry {
                        title
                        url
                        uri
                    }
                }
                ... on Craft_contentMatrix_excerptBlock_BlockType {
                    excerpt
                    typeHandle
                }
                ... on Craft_contentMatrix_productsBlock_BlockType {
                    typeHandle
                    related
                    productsList {
                        ... on Craft_shop_product_Entry {
                            id: remoteId
                            title
                            uri
                            typeHandle
                            plainText
                            multipleImages {
                                url
                                title
                                ... on Craft_images_Asset {
                                    url
                                    title
                                    imageFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                    typeHandle
                    eventsList {
                        ... on Craft_projectsAndEvents_default_Entry {
                            id: remoteId
                            title
                            uri
                            typeHandle
                            eventDate
                            plainText
                            singleImage {
                                url
                                title
                                ... on Craft_images_Asset {
                                    url
                                    title
                                    imageFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                    blockCopy
                    blockImage {
                        ... on Craft_images_Asset {
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                            url
                            title
                        }
                    }
                    typeHandle
                }
                ... on Craft_contentMatrix_singleMedia_BlockType {
                    singleAsset {
                        ... on Craft_images_Asset {
                            url
                            title
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    typeHandle
                }
                ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                    typeHandle
                    assets {
                        ... on Craft_images_Asset {
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                            url
                            title
                        }
                    }
                }
                ... on Craft_contentMatrix_imageCarousel_BlockType {
                    typeHandle
                    carouselImages {
                        ... on Craft_images_Asset {
                            url
                            title
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_linkBlocks_BlockType {
                    typeHandle
                    linkItem {
                        ... on Craft_linkItem_BlockType {
                            richText
                            buttonCopy
                            buttonUrl
                        }
                    }
                }
                ... on Craft_contentMatrix_accordionBlock_BlockType {
                    typeHandle
                    accordionItem {
                        ... on Craft_accordionItem_BlockType {
                            accordionLabel
                            accordionContent
                        }
                    }
                }
                ... on Craft_contentMatrix_projectList_BlockType {
                    showEvents
                    typeHandle
                }
                ... on Craft_contentMatrix_newsletterSignup_BlockType {
                    showNewsletter
                    typeHandle
                }
                ... on Craft_contentMatrix_giveCloudForm_BlockType {
                    typeHandle
                    showForm
                }
                ... on Craft_contentMatrix_donationForm_BlockType {
                    typeHandle
                    showForm
                }
                ... on Craft_contentMatrix_dynamicButton_BlockType {
                    typeHandle
                    buttonCopy
                    buttonLink
                    position
                }
                ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                    typeHandle
                    videoLinks {
                        ... on Craft_videoLinks_BlockType {
                            videoLink
                        }
                    }
                }
            }
        }
    }
`;

const StyleguidePage = ({ data: { page } }) => {
    const content = page.contentMatrix;

    return <div className="page-wrapper">{content.map((block, i) => renderSwitch(block, i))}</div>;
};

export default StyleguidePage;
